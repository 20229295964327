.livelyContainer{
    /* padding: 50px 0px 0px 0px; */
    margin-top: clamp(3.5rem,4vw,5rem);
    margin-bottom: clamp(3.5rem,4vw,5rem);
}

.pdp_sec1 { margin-top: 20px; display: inline-block;width: 100%;    border-radius: 5px; box-shadow: 0px 3px 6px rgb(49 49 49 / 16%);    padding: 20px;}
.pdp_sec1 h1{min-height: 3rem; font-family: 'Bell MT';font-size: 28px;color: #3e4245;padding-bottom: 10px;font-weight:500;letter-spacing: 0.15px}
.pdp_sec1 p{letter-spacing: 0.25px; line-height: 1.7; min-height: 1.25rem;}
.pdp_sec1 .pwa_desc {
    margin-bottom: 20px;
    letter-spacing: .25px;
    min-height: 1.5rem;
    font-family: "Barlow";
    color: #3e4245;
}
.pdp_sec1 .starReviewSec{
    min-height: 1.25rem;
}
.Pincode_text{margin: 0;}
.pdp_sec1 li { float: left; margin-right: 16px;
  color: #AD3132;letter-spacing: 0.25px; font-size: 13px;}
    .pdp_sec1 li a{color: #AD3132;}
    .origin{ color: #AD3132;margin-bottom: 10px;font-size: 13px;}
.pdp_sec2 {position: fixed;bottom:5px;    z-index: 2;left: 3%;margin-top: 20px; display: inline-block; width: 94%;border-radius: 5px;box-shadow: 0px 0px 16px rgb(82 53 40 / 16%);overflow: hidden;}
.pdp_sec2 li button{width: 100%;border: none;font-size: 12px;color: #3e4245; display: block; font-weight: 600;  
     padding:13px 0;position: relative;text-transform: uppercase; opacity: 1;    letter-spacing: 1.25px; background: #fff;}
.pdp_sec2 li{ float: left;    width: 50%;text-align: center;}
.pdp_sec2 li:last-child button{  color: #fff; background: #3e4245;}
.pdp_sec2 li:last-child button b{ display: inline-block; vertical-align: text-bottom;margin-right:5px !important; position: relative; top:1px;}
.pdp_sec3 {margin-top:20px;}
.pdp_sec2 li:last-child button b div{ display: block !important;}
.pdp_sec3 ul{margin-top:0px; display: flex; width: 100%;justify-content: center}
.pdp_sec3 .h5pdp_quantity{font-size:14px; line-height: 1.3;  font-family: 'Barlow';font-weight:400;margin-bottom: 8px; color: #3e4245;}
.pdp_sec3 li{padding:10px;    display: inline-block;margin:0 10px;}
.pdp_sec3 li a{background-image: url("/images/location.inline.svg"); width: 58px; height:58px; display: block;    font-size: 12px;
text-align: center;padding-top: 18px; color: #3e4245;margin: 0 auto;text-transform: lowercase;    border: 1px solid #3e4245;
border-radius: 50%; background-image: none; background-color: #fff;}
.pdp_sec3 li a.active{background-image: url("/images/size-selected.inline.svg"); font-weight: 600; color: #fff; background-color: #3e4245;background-image: none;  }
.product .gallery{text-align: center;width: 100%;background-color: #f9f8ee;
    position: relative;padding: 90px 0 20px 0;background-image: linear-gradient(0deg,rgb(218 218 218 / 50%), #fffdee)}
    .productLayoutNew .gallery{text-align: center;width: 100%;background-color: #f9f8ee;
        position: relative;padding: 90px 0 20px 0;background-image: linear-gradient(0deg,rgb(218 218 218 / 50%), #fffdee)}
.saving_color{color:#C4601D !important;}
.pdp_sec4{display: inline-block;width: 100%; margin-top: 20px;background: #fff;border: 1px solid #3e4245;padding: 15px;    padding-bottom: 10px;}
.pdp_sec4 .h5pdp_pincode {font-family: 'Barlow';line-height: 1.2;padding-bottom: 6px;font-size: 12px;color: #3e4245;letter-spacing: 0.5px;text-transform: capitalize;font-weight: 400;}
.location_icon img {vertical-align: bottom;width: 22px;}
.pdp_sec4 .h6pdp_pincode {font-size: 12px;color: #828282;padding-bottom: 6px;letter-spacing: 0.25px;padding-top: 3px;line-height: 1.2;}
.input_check {width: 100%; display: inline-block;    padding-top: 4px;border-bottom: 1px solid #3e4245;}
.input_check input {  border: 0;  width: 70%;padding: 2px;display: inline-block;float: left;background: initial;}
.input_check button { background:none;font-weight: 600; width: 25%;  text-align: right; display: inline-block; color: #3e4245;padding: 3px; border-radius: 5px;font-size: 12px;letter-spacing: 1.25px; float: right;text-transform: capitalize;font-family: 'Barlow';}
.pdp_sec4  p {color: #3e4245; font-size: 12px;}
.pdp_sec4 p img { position: relative; top: 5px;}
.pdp_sec5{margin-top:20px; display: inline-block; width: 100%;border: 1px solid #3e4245; padding: 15px; position: relative;    padding-left: 53px; background-color: #fff;}
.pdp_sec5 span {position: absolute;left: 15px;}
.pdp_sec5 p { font-size: 13px; margin-bottom:10px;letter-spacing: 0.5px;}
.pdp_sec5 .col em {float: left;letter-spacing: 0.4px;color: #C4601D;font-size: 12px;}
.pdp_sec5 a { font-size: 13px;font-weight: 600;color: #3e4245 !important;text-transform: capitalize;float: right; cursor: pointer;}
.pdp_sec6{margin-top:69px; display: inline-block; width: 100%;background-color: #fff; padding:20px 20px 10px;position: relative; border: 1px solid #3e4245;}
.pdp_sec6 p{ font-size: 14px; position: relative;padding-left: 30px; margin-bottom: 10px;}
.pdp_sec6 p span {position: absolute;left:0px;}
.pdp_sec6 p em {display: block;}
.pdp_sec6 .h2pdp_sec6{font-family: 'Bell MT';font-size: 22px;margin-bottom: 10px; color: #3e4245;
    font-weight: 600; line-height: 1.3;}
.bag_align_middle{vertical-align: middle;}
.pdp_sec7{margin-top:20px; display: inline-block; width: 100%; }
.pdp_sec7 .h2pdp_sec7{font-family: 'Bell MT';font-size: 22px;margin-bottom: 10px; text-align: left;font-weight: 600; line-height: 1.3;color: #3e4245;}
.pdp_sec7 ul li{font-family: 'Barlow'; font-size: 12px; text-align: center;    width: 40%;    flex-shrink: 0;}
.pdp_sec7 ul li span div{ display: block !important;}
.pdp_sec7 ul{flex-wrap: nowrap; white-space: nowrap; overflow: auto;-webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;-ms-overflow-style: none; scrollbar-width: none;-webkit-transform: translateZ(0);
     transform: translateZ(0); scroll-behavior: smooth; display: flex;}
     .pdp_sec7 ::-webkit-scrollbar{ width: 0; height: 0;}
.pdp_sec7 ul li img { padding-bottom: 5px;margin: 0 auto;
display: block;}
.pdp_sec8{margin-top:30px; display: inline-block; width: 100%;  }
.pdp_sec8 > div:nth-child(2) {
    position: relative;
    background: rgb(240 237 237 / 60%);
 min-height: 180px;margin-bottom: 10px;    padding-bottom: 0px;    display: flex;
 align-items: stretch;flex-direction: column;justify-content: center;
}
.pdp_sec8 > div:nth-child(3) {
    position: relative;
    background: rgb(250 243 233 / 60%);margin-bottom: 10px;
 min-height: 180px;    padding-bottom: 0px;    display: flex;
 align-items: stretch;flex-direction: column;justify-content: center;
}
.pdp_sec8 > div:nth-child(4) {
    position: relative;
    background: rgb(240 237 237 / 60%);
  min-height: 180px;margin-bottom: 10px;    padding-bottom: 0px;    display: flex;
  align-items: stretch;flex-direction: column;justify-content: center;
}
.pdp_sec8 > div:nth-child(5) {
    position: relative;
    background: rgb(250 243 233 / 60%);
 min-height: 180px;margin-bottom: 10px;    padding-bottom: 0px;    display: flex;
 align-items: stretch;flex-direction: column;justify-content: center;
}
.pdp_sec8 > div:last-child { margin-bottom: 0;}
.pdp_sec8 span {
    display: block;
    margin-top: 20px;
}

.pdp_sec8 h3 {
    font-size: 14px;
    font-weight: 600;
    width: 192px;
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: 'Bell MT';
    letter-spacing: 0.25px;
    line-height: 1.4;
    color: #3e4245;
}
.pdp_sec8 p {
    font-size: 14px;
    width: 190px;
    max-width: 100%;
    position: relative;
    letter-spacing: 0.25px;
    color: #3e4245;
}
.pdp_sec8 img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-position: center center;
    object-fit: contain;
}
.snapmintDes{
    margin: 10px 0px;
}
.youtube_icon_red{width: 45px; height: 32px !important;}
.pdp_sec8 h2{font-size: 22px;margin-bottom: 10px; text-align: left;font-weight: 600; color: #3e4245;}
.pdp_sec7_colm1 {    position: relative; background: #f0eded; width: 100%;display: inline-block;    margin-bottom: 10px;}
.pdp_sec7_colm1 h3 { font-size: 14px;font-weight:600;width: 192px;max-width: 100%;    padding-top: 20px; padding-bottom: 10px;font-family: 'Open Sans';letter-spacing: 0.25px;    line-height: 1.4;}
.pdp_sec7_colm1 p {font-size: 14px;  width: 190px; max-width: 100%;    position: relative;letter-spacing: 0.25px;}
.pdp_sec7_colm1 span { position: absolute; bottom: 0; right: 0;}
.pdp_sec7_colm1 span img {display: block;}
.pdp_sec7_colm2 span {  left: 0; top: 0;}
.pdp_sec7_row { float: right;    position: relative;}
.pdp_sec7_colm1.pdp_sec7_colm2 { height: 180px; display: block;background: #faf3e9; }
.pdp_sec9{margin-top:30px; display: inline-block; width: 100%;}
.pdp_sec9 .h2pdp_sec9{font-size: 22px;margin-bottom: 10px; text-align: left;font-weight: 600; line-height: 1.3; color: #3e4245;   font-family: 'Bell MT';}
.pdp_sec9 ul li span { display: block; margin: 0 auto 5px;}
.pdp_sec9 ul li {text-align: center;font-size: 14px;color: rgb(49 49 49 / 50%); font-weight: 600;}
.pdp_sec9_colm { padding-top: 20px;display: inline-block; width: 100%;}
.pdp_sec9_colm p {  margin-bottom: 2px;font-size: 13px;letter-spacing: 0.25px; color: #3e4245;}
.pdp_sec9_colm p b {   font-weight: 600; font-size: 14px;}
.checkbox_row {position: relative; width: 100%;display: inline-block;padding-left: 22px;}
.checkbox_row input {  position: absolute; left: 0;top: 2px;}
.checkbox_row p {  margin-bottom:5px;}
.checkbox_row p em {color:#828282;}
.checkbox_row p span {color:red;}
.pdp_sec9_colm .col span, .pdp_sec9_colm .col h5, .pdp_sec9_colm .col h4 {display: inline-block;}
.pdp_sec9_colm .col span {margin-right:20px;font-size: 12px;letter-spacing: 0.25px;}
.pdp_sec9_colm .col h5 {font-size: 22px;color:#c7c7c7; text-decoration: line-through !important; margin-right: 10px;font-family: Open Sans;}
.pdp_sec9_colm .col h4 {font-size: 22px;font-weight: 600;font-family: Open Sans;}
.white_btn{position: relative; width: 100%;display: inline-block;    padding-top: 20px;}	
.white_btn button { background: #3e4245; padding: 7px 0; display: inline-block;width: 100%; color: 
#fff;letter-spacing: 1.25px;text-transform: capitalize;font-size: 12px; font-weight: 600; text-align: center;}
.pdp_sec9 ul li abbr { position: absolute;right:-10px;    top: 50%;}
.pdp_sec9 ul li{ position: relative;}
.pdp_sec10{margin-top:30px; display: inline-block; width: 100%;}
.pdp_sec10 .h2pdp_sec10{font-size: 22px;margin-bottom: 10px; text-align: left;font-weight: 600; line-height: 1.3;font-family: 'Bell MT';color: #3e4245; }
.pdp_sec10 img {height: 65px;}
.pdp_sec10 ul{flex-wrap: nowrap; white-space: nowrap; overflow: auto;-webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;-ms-overflow-style: none; scrollbar-width: none;-webkit-transform: translateZ(0);
     transform: translateZ(0); scroll-behavior: smooth; display: flex;}
     .pdp_sec10 ::-webkit-scrollbar{ width: 0; height: 0;}
     .pdp_sec10 li{width: 40%;  flex-shrink: 0;text-align: center;}
.pdp_sec11{margin-top:30px; display: inline-block; width: 100%;}
.pdp_sec11 .h2pdp_sec11{font-size: 22px; text-align: left;font-weight: 600;font-family: 'Bell MT'; color: #3e4245;
    line-height: 1.3;}
.pdp_sec11 .row{flex-wrap: nowrap; white-space: nowrap; overflow: auto;-webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;-ms-overflow-style: none; scrollbar-width: none;-webkit-transform: translateZ(0);
     transform: translateZ(0); scroll-behavior: smooth; display: flex;padding: 5px 0; margin: 0 -7px;}
     .pdp_sec11 ::-webkit-scrollbar{ width: 0; height: 0;}
     .pdp_sec11 .col-8{white-space:normal; padding: 0 7px;}
.product_item span {  background: #eeeeee; display: block;  width: 100%;padding: 5px; margin-bottom: 15px;border-radius: 5px;}
.product_item {  position: relative; width: 100%;}
.product_item h3 { font-size: 18px; color: #000000;   font-weight: 600; line-height: 1.3; padding-bottom: 10px;}		
.product_item p{ color: #000; margin-bottom: 15px;}		
.product_item p a{ font-weight: 600; display: block;color: #a9671e; text-transform: uppercase; letter-spacing: 1.25px; font-size: 12px;    padding-top: 4px;}
.btn_bag a {text-align: center; letter-spacing: 1.25px; font-size: 12px;   line-height: 24px; color: #ffffff;
text-decoration: none solid rgb(255, 255, 255); box-shadow: 0px 0px 16px rgb(82 53 40 / 16%); background: #C4601D;
display: block; border-radius: 5px;  text-transform: uppercase;  font-weight: 600; padding:11px;}
.product_item h4 { font-size: 20px; font-family: Open Sans;  font-weight: 600; line-height: 1.2; padding-bottom: 15px;}
.pdp_sec11-row { display: block; width: 100%;margin-bottom: 20px;}
.pdp_sec11-row span { display: block; border-radius: 5px;overflow: hidden; position: relative; margin-bottom: 12px;}
.pdp_sec11-row p { margin-bottom: 0;font-size: 14px; font-style: italic; line-height: 1.3;letter-spacing: 0.25px;}
.pdp_sec11-row span img{ display: block;}
.pdp_sec12{ padding-top: 30px; display: inline-block; width: 100%;text-align: left;}
.pdp_sec12 .h2pdp_sec12{font-size: 22px; text-align: left;font-weight: 600;border-bottom: 1px solid #f0f0f0; padding-bottom: 10px; font-family: 'Bell MT'; color: #3e4245;   line-height: 1.3; margin-bottom: 15px;}
.pdp_sec12 li { display: inline-block; width: 100%; border-bottom: 1px solid #f0f0f0; margin-bottom: 15px;}
.pdp_sec12 li h3 {color: #3e4245; line-height: 1.2; font-size: 20px;font-weight:500;padding-bottom: 10px;float: left; width: 75%;}
.pdp_sec12 li h3 span { font-family: 'Open Sans';display: block; color: #c7c7c7;font-size: 14px;  font-weight: 400; padding-top: 8px;}
.pdp_sec12 .star_rating { float: right;font-size: 14px; color: #946134;display:block;}
.star_rating img { display: inline-block;      width: 12px; }
.pdp_sec12 p {width: 75%; font-size: 13px;margin-bottom: 15px;letter-spacing: 0.5px;}
.view_all a {  letter-spacing: 1.25px;text-transform: capitalize;color:#3e4245;  font-weight: 600;  display: block;font-size: 13px;}
.pdp_sec12 li:last-child { margin-bottom: 20px; }
.pdp_sec13{margin-top:30px; display: inline-block; width: 100%;}
.pdp_sec13 .h2pdp_sec13{font-family: 'Bell MT';font-size: 22px; line-height: 1.3; padding-bottom: 10px; text-align: left;font-weight: 600;border-bottom: 1px solid #f0f0f0;color: #3e4245;}		
.pdp_sec13 .view_all a { margin-top: 20px;}

.pdp_sec13 .accordion { margin-bottom: 20px;}
.pdp_sec13 .accordion_item { border-radius: 0; border: 0; margin: 0;}		
.pdp_sec13 .accordion_item h3 a { position: relative;display: block; width: 100%; font-size: 14px; font-weight: 600;padding: 17px 0; border-bottom: 1px solid #f0ddd1;font-family: 'Open Sans';letter-spacing: 0.25px;padding-right: 30px;}
.pdp_sec13 .accordion_item h3 a:before {
content: "";position: absolute; background-image: url("/public/images/drop-down-arrow.inline.svg"); width: 28px; height: 28px; right: 0;-webkit-transition: all 0.32s ease-out; -moz-transition: all 0.32s ease-out;-o-transition: all 0.32s ease-out; transition: all 0.32s ease-out;     top: 13px;}
.pdp_sec13 .accordion_item h3 a.collapsed:before {
content: "";position: absolute; background-image: url("/public/images/drop-down-arrow.inline.svg"); width: 28px; height: 28px; right: 0;transform: rotate(180deg); }
.pdp_sec13 .accordion_body {padding: 20px 0;}	
.pdp_sec13 .accordion_body ul li {font-size: 14px;}
.pdp_sec14{margin-top:30px; display: inline-block; width: 100%;}
.pdp_sec14 .row{flex-wrap: nowrap; white-space: nowrap; overflow: auto;-webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;-ms-overflow-style: none; scrollbar-width: none;-webkit-transform: translateZ(0);
     transform: translateZ(0); scroll-behavior: smooth; display: flex; margin: 0 -8px;}
     .pdp_sec14 ::-webkit-scrollbar{ width: 0; height: 0;}
     .pdp_sec14 ul li{ white-space:normal; padding: 0 7px;margin-top: 10px; margin-bottom: 15px; }
.pdp_sec14 .h2pdp_sec14{font-family: 'Bell MT';color: #3e4245;font-size: 22px; text-align: left;font-weight: 600;line-height: 1.3;}	
.kama_journal_box {  box-shadow: 0px 0px 6px rgba(82,53,40,0.2); padding: 10px; border-radius: 5px;    height: 100%;}		
.kama_journal_box span {display: block;margin-bottom: 10px;overflow: hidden;   border-radius: 5px;}		
.kama_journal_box h4 { font-size: 18px;letter-spacing: 0.15px; line-height: 1.3; font-weight: 500;padding-bottom:8px;}	
.kama_journal_box span img{ display: block;height: auto; min-height: 100%; max-height: 100%;}

.pdp_sec15{margin-top:30px; display: inline-block; width: 100%;position: relative;}
.pdp_sec15 .h2pdp_sec15{font-size: 22px;margin-bottom: 10px; text-align: left;font-weight: 600;    line-height: 1.3;
    font-family: Cormorant Garamond;}		
.recently_viewed_box span {  height: 114px; display: inline-block; float: left; margin-right: 10px;}
.recently_viewed_box span img { display: block;height: inherit;}
.recently_viewed_box h3 { font-size: 18px; line-height: 1.3; font-weight:600; padding-bottom: 8px; }	
.recently_viewed_box p { font-size: 14px;text-transform: capitalize;}		
.recently_viewed_box { width: 100%; padding: 15px; border: 1px solid #C4601D; border-radius: 5px;display:inline-block}
.recently_viewed_box p { font-size: 14px; margin: 0;}
.recently_viewed_box span a{   float: left; width: 100px;}
.modal.ingredient_popup .modal-dialog { transform: none;}
.ingredient_popup .modal-content {border: 0;    overflow: hidden;}		
.ingredient_popup .modal-body{ padding: 0;}
.ingredient_popup .btn-close { opacity: 1; color: #fff; background: none;font-size: 22px;float: right;}	
.ingredient_popup .modal_body_content { text-align: left; padding: 10px 20px 15px;}	
.modal_body_content h2 {font-size: 26px; color: #000; margin-bottom: 5px;font-weight: 700;}	
.modal_body_content p {font-size: 14px; margin-bottom: 5px;}
.modal_body_content a { font-size: 14px;color: #946134; font-weight: 600;} 
.pdp_sec16{ width: 100%; padding-top:25px; display: table;}
.pdp_sec16 ul li{letter-spacing: 0.25px;  list-style-type: disc;}
.pdp_sec16 ul{ padding-left: 20px; padding-bottom:10px;}
.pdp_sec16 h2{font-size: 22px; font-weight: 600; margin-bottom: 8px;}
.pdp_sec16 blockquote { position: relative;display: table; width: 100%; border: 1px solid #f0ddd1;  border-radius: 5px;padding:15px 15px; margin-top: 30px;margin-bottom: 20px;}
.pdp_sec16 blockquote p{ margin: 0; font-size: 13px; letter-spacing: 0.5px; font-style: italic;}
.pdp_sec16 blockquote span:first-child { position: absolute;top:-8px;    background: #fff; padding: 0 5px;}
.pdp_sec16 blockquote span:last-child{position: absolute; bottom: -8px; right: 15px;transform: scaleX(-1) scaleY(-1);     background: #fff; padding: 0 5px;}
.pdp_sec16 blockquote span img{ width:16px;}
.pdp_sec16 abbr { position: relative;display: table; width: 100%; border: 1px solid #3e4245;  border-radius: 5px;padding:15px 15px; margin-top: 30px;margin-bottom: 20px;}
.pdp_sec16 abbr p{ margin: 0; font-size: 13px; letter-spacing: 0.5px; font-style: italic;}
.pdp_sec16 abbr span:first-child { position: absolute;top:-8px;    background: #fff; padding: 0 5px;}
.pdp_sec16 abbr span:last-child{position: absolute; bottom: -8px; right: 15px;transform: scaleX(-1) scaleY(-1);     background: #fff; padding: 0 5px;}
.pdp_sec16 abbr span img{ width:16px;}
.pdp_sec17 {  margin-top: 30px;width: 100%;}
.pdp_sec17 h2{font-size: 22px; font-weight: 600; margin-bottom:8px; color: #3e4245;}	
.pdp_sec17 ul li{letter-spacing: 0.25px;  list-style-type: disc;font-family: 'Barlow';color: #3e4245;}
.pdp_sec17 ul{ padding-left: 20px; padding-bottom: 8px; }	
.pdp_sec17 p em{ color: #828282;}
.pdp_sec17 p:last-child{ margin-bottom: 0;}
.like_colm { position: absolute; right: 15px;top: 15px;}
.pdp_sec13 .accordion_body p{letter-spacing: 0.25px;}		
.pdp_sec13 .accordion_body p:last-child { margin: 0;}
.icon_shoping { position: absolute;  right: 15px;top:50%;-webkit-transform: translate(0,-50%);
-ms-transform: translate(0,-50%);transform: translate(0,-50%);}
.productLayoutNew .icon_shoping { position: absolute;  right: 15px; bottom: 0; top: auto;}
.icon_shoping ul li.wishlist_box a svg{position: unset;}
.icon_shoping ul li.share_box a{background-image: url("/images/share.inline.svg"); width: 28px; height: 28px; display: block;}
.icon_shoping ul li { margin: 12px 0;}
.gallery:before{    content: "";background-image: url(/images/motifs-icon-bg.inline.svg);position: absolute;
    width: 146px;  height: 232px; left: 50%; transform: translate(-50%, -50%);  top: 40%;background-size: cover;}
.productLayoutNew .gallery:before{
    background: none !important;
}
.pdp_sec17{ width: 100%; display: inline-block;}
.pdp_sec17 .h4pdp_price{color: #3e4245; font-size: 20px; font-weight: 600;font-family: 'Barlow'; line-height: 1.3;}
.pdp_sec17 .h4pdp_price span{ font-size: 14px; margin-right: 5px; font-weight: 400;}
.listing_pdp{float: right;}
.listing_pdp abbr{float: left;   font-size: 14px;margin-right: 12px; padding-top:2px;}
.listing_pdp .number {width:94px; float: right;border: 1px solid #f0ddd1;
     border-radius: 5px;    padding: .7px 10px;    padding-right: 5px;}
.listing_pdp .number input,.listing_pdp .main_number { border: 0; display: inline-block; width: 50px;
     text-align: center;font-size: 14px;color:#AD3132;    top: -2px; position: relative;}
.listing_pdp .number span { font-size: 24px; font-weight: 600;    line-height: 1;   }
.ques{width: 100%;font-size: 14px;  font-weight: 600;padding: 17px 0 !important;  border-bottom: 1px solid #f0ddd1 !important; font-family: 'Open Sans';  letter-spacing: 0.25px;}
.ans{ padding: 20px 0 !important;display: block !important;}
.relatedProductPDP{
    flex-direction: column;
}
.pdp_product_scroll{
flex-wrap: nowrap;
overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    scroll-behavior: smooth;
    margin: 0 -8px;
}
.modal a{
    position: relative;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    text-align: right;
}
.modal h3{
    color: #313131;
    font-size: 20px;
    font-weight: 600;padding-bottom: 5px;letter-spacing: 0.25px;
}
.modal a img{
    width: 22px;
}
.modal {
    background: #faf7f5;    height: 100%;
    overflow: auto;
}
.modal p{    font-size: 13px;  letter-spacing: 0.5px;}
.pdp_sec2 .out_of_stock_btn{ width: 100%;}
.pdp_sec2 .out_of_stock_btn button{     border-color: #d69061 !important;
    background-color: #d69061 !important;}
.listing_pdp .number span:last-child{ top:2px; position: relative;}
.pdp_sec4 small{ display: block;padding-bottom: 5px;    font-size: 12px;}
.delivery_error{ color: red;}
.delivery_success{ color:green;}
.pdp_sec17 .pdp_sec8{ margin-top: 5px;}
.pdp_sec19{width: 100%; display: inline-block; padding-top: 30px;}
.pdp_sec19 .h2pdp_sec19{    font-size: 22px; font-weight: 600; margin-bottom: 8px; line-height: 1.3;font-family: Cormorant Garamond;}
.pdp_sec19 p:last-child{ margin-bottom: 0;}
.pdp_sec1 li:last-child{ margin: 0;}
.reviewform .inputrow {padding-left: 0;    padding-right: 0;}
.reviewform h4 {color:green}
.star_rating .share_user a{ float: left;     margin: 0 1px 0 3px; position: relative;}
.star_rating .share_user span{     font-size: 12px;  font-weight: 400; letter-spacing: 0.4px;
    margin-right:4px; float: left;}
    .reviewform h4{    font-family: 'Barlow';letter-spacing: 1.25px; text-transform: uppercase;
 color: #AD3132; font-weight: 600; display: block; font-size: 13px;}
 .reviewform h4 a{ color: #3e4245; text-transform: capitalize;}
 .product_amaaya{ display: block; margin-top: -20px; margin-bottom: 5px;}
.reviewform h5{letter-spacing: 0.15px;font-size:19px;color: #000000; font-weight: 500;
    font-family: Cormorant Garamond;    line-height: 1.5; padding-bottom: 10px;}
    .inputstar svg{ font-size:35px; }
    .reviewform .inputcolm fieldset, .reviewform .inputcolmtext fieldset { border-radius: 0;border: 0; }
    .reviewform .inputcolm input { font-size: 14px; padding: 8px 0px;letter-spacing: 0.5px; 
        font-weight: 600; border-bottom: 1px solid #f0f0f0; background-color: #fff;}
    .reviewform .inputcolm label, .reviewform .inputcolmtext label{font-size: 12px;letter-spacing: 0.5px;text-transform: capitalize;
        transform: inherit;position: relative; color: #AD3132; display: block; padding-bottom: 2px;}
        .reviewform .inputcolm { padding-bottom:25px;}
        .inputcolmtext textarea{font-size: 14px; padding: 12px 15px;letter-spacing: 0.5px; min-height: 130px; 
            font-weight: 600; border: 1px solid #f0f0f0; border-radius: 0px;margin-top: 10px; background-color: #fff;}
            .reviewform .inputcolmtext div { padding: 0; }
            .reviewform .white_btn button{    padding: 12px 30px; width: auto;  float: right;}
            .reviewform input:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px white inset;
                box-shadow: 0 0 0px 1000px white inset;font-size: 14px; }
.reviewform h3{font-size:33px; padding-bottom:15px; line-height: 1;letter-spacing: 0.25px; color: #3e4245;
    border-bottom: 1px solid #f0f0f0; margin-bottom: 30px;}
    .review_view_main .view_all{ padding-bottom: 30px;}
    .reviews_product{border: 1px solid #342453; display: inline-block; position: relative; 
        padding-left: 90px;   min-height: 100px; padding: 10px; padding-left:100px;    max-width: 330px; margin-bottom: 20px;}
    .reviews_product span{width: 77px;  height: 77px; padding: 5px;  border-radius: 5px; left:10px;  position: absolute;   background: #eeeeee;}
    .reviews_product h6{letter-spacing: 0.15px;font-size: 19px; color: #3e4245;   font-family: 'Bell MT'; font-weight: 500; padding-bottom: 10px;}
    .reviews_product h4{font-family: 'Barlow';font-size: 16px; font-weight: 600;}
    .reviews_product h4 abbr{font-size: 12px;color: #828282; margin-right: 3px;    text-transform: capitalize;
        font-weight: 400;letter-spacing: 0.4px;}
        .pdp_sec12 .reviews_rating li{ font-size: 13px;    border: 0; margin: 0; padding: 0;}
.reviews_rating .star_rating{ font-size: 16px;display: inline-block;margin-right: 25px;    float: none;}
 .reviews_rating{ padding-top: 15px;}
 .pdp_sec9 ul li:nth-child(3) {padding-top: 15px;}
 .pdp_sec9 ul li:nth-child(4) {padding-top: 15px;}
 .pdp_sec9 ul li:nth-child(even) abbr {display: none;}
 .pdp_sec20{ margin-top: 60px; width: 100%;}
 .pdp_sec20 h2{font-size: 33px; letter-spacing: 0.25px; margin-bottom: 15px;  text-transform: capitalize; color: #3e4245;
 font-weight: 400; line-height: 1;}
 .pdp_sec20 ul{padding-left: 20px;padding-bottom: 10px;}
 .pdp_sec20 ul li{font-size: 13px;letter-spacing: 0.5px;  padding-bottom: 4px;font-family: 'Barlow'; color: #3e4245;   list-style-type: disc;}
 .pdp_sec20 iframe{ display: block; border-radius: 5px;}
 .pdp_added{    font-size: 13px; letter-spacing: 0.25px;text-align: center;width: 100%;
    display: inline-block; padding-top: 12px;}
 .pdp_added a{ display: inline-block; font-size: 12px; padding-left: 5px;    font-size: 12px;
    color: #C4601D;text-transform: uppercase;    font-weight: 600;}
    .pdp_added b{ position: relative; top: 1px;    margin-right: 2px;}
    .pdp_sec1 .pdp_star_color{ color: #fff;  font-weight: 600;  background: #008000;color: #fff;padding: 0px 5px;border-radius: 5px;}
    .pdp_zoomimg{    position: absolute;right: 10px; top: 20px; background: #fff; width: 580px;
 height: 440px; box-shadow: 0px 0px 6px rgb(82 53 40 / 20%);z-index: 9; overflow: hidden;}
 .product{ position: relative;}
 .productLayoutNew{ position: relative;}
 .not_zoomimg{visibility: hidden;position: absolute;}
 .faqform{ padding-top: 30px;}
 .modal_freetrial{position:fixed; left: 0; width: 100%; z-index: 9999;    top: 0px;    height: 100%;}
 .modal_freetrial_main{ width:400px; position:relative;background: #fff; border-radius: 6px;
    margin: 0 auto;z-index: 999;    padding: 5px;     margin: 50px auto;
   box-shadow: 0px 0px 6px rgb(49 49 49 / 16%); }  
   .close_btn{ position: absolute;     z-index: 1;   background: #f9f4e8;     right: -19px;  top: -19px; width: 38px;
    height: 38px; border-radius: 50%;text-align: center;display: flex;justify-content: center;
    align-items: center;}     
    .modal_freetrial:before { content: "";width: 100%;  height: 100%; background: rgb(0 0 0 / 60%);position: fixed;top: 0;left: 0;}
    .modal_body {   width: 100%; display: block;}
    .simpl_text a{ cursor: pointer;    display: inline-block;}
    .simpl_text a span{position: relative; top: 4px; margin: 0 3px;}
    .simpl_text a abbr{ position: relative; top: 1px;}
    .modal_freetrial_main_colm{     width: 100%; position: relative;   margin: 0 auto;
        height: 100%;  overflow-x: hidden; overflow-y: auto;}
        .simpl_text{ margin-bottom: 0; line-height: 1.2;}
.carousel_item_padding{padding:0 12px;}
.cutoffprice{  padding-left: 12px;color: rgb(62 62 62 / 50%);font-size: 21px;text-decoration: line-through !important;}

.anniversary_sec12{ width: 100%; text-align: center;     padding: 15px 12px 31px; display: inline-block;
    font-size: 18px;letter-spacing: 1.25px;line-height: 1;    font-weight: 600;     background: #f9f8f8; color: #c4601d;}
  .anniversary_sec12 h2{font-size: 26px;text-align: center; font-weight: 500;    line-height: 1;color: #313131; letter-spacing: 0.25px; }
    .anniversary_sec12 h2 a{    font-size: 12px;font-family: 'Open Sans'; box-shadow: 0px 0px 16px rgb(82 53 40 / 16%);
      background: #c4601d;color: #fff;text-transform: uppercase;display: inline-block;
   padding: 8px 12px;border-radius:5px;    letter-spacing: 1.25px; font-weight: 600;}
   .anniversary_sec12 h2 sup{    top: -0.2em;    font-size: 26px;}
    .anniversary_sec12 h2 abbr{ display: block;}
  .anniversary_sec12 span{ position: relative; background: #c4601d;color: #fff;  padding: 10px 12px;  display: inline-block;border-radius: 10px;margin: 0 10px;}    
  .anniversary_sec12 span:before{ content: "Hours"; position: absolute;font-size: 13px; left: 50%; bottom: -18px; text-align: center; color: #c4601d; transform: translate(-50%, 0); letter-spacing: normal;}
  .anniversary_sec12 span.a_sec12_2:before{content: "Minutes";}
  .anniversary_sec12 span.a_sec12_3:before{content: "Seconds";}
  .anniversary_sec12 span.a_sec12_4:before{content: "Days";}
  .anniversary_sec12 > div{display: flex;justify-content: center;align-items: center;}
  .pdp_sec12 .star_rating span{margin-right: 2px;}
  .pdp_sec12 .star_rating .star_imgPdp{width: 12px; height: 11px;}

@media (min-width: 992px){
    .pdpBanner{
        aspect-ratio: 1 / 1;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    /* .product_container{max-width: 1500px;} */
    .productLayoutNew .carousal_sec{ width: 50%;}
    .product .p-col-0{    width: 55%;}
    .product .col-md-6{    width: 45%;}
    .review_view_main{ position: relative;}
    .pdp_sec14 ul, .pdp_product_scroll{ overflow: inherit;}
    .pdp_sec7{ margin-top: 60px;    background: rgb(240 237 237 / 40%);  padding: 50px 0 50px;}
    .pdp_sec7 .h2pdp_sec7{font-size: 33px; letter-spacing: 0.25px;  margin-bottom: 22px; text-transform: capitalize; line-height: 1;
        font-weight: 400;}
    .pdp_sec7 ul{    display: flex;flex-wrap: wrap; margin:0 -12px;}
.pdp_sec7 ul li{ padding: 0 12px; width: 20%;text-align: center; font-size: 23px;  color: #3e4245;letter-spacing: 0.15px;
    font-weight: 500; padding-bottom: 5px;    line-height: 1.3; font-family: 'Barlow';}
    .pdp_sec7 ul li:nth-child(6), .pdp_sec7 ul li:nth-child(7), .pdp_sec7 ul li:nth-child(8),
    .pdp_sec7 ul li:nth-child(9), .pdp_sec7 ul li:nth-child(10){ padding-top:25px;}
.pdp_sec7 ul li span{ width: 150px; height: 150px;border-radius: 50%; overflow: hidden; display: flex;background-color: #fff;
    flex-direction: column; justify-content: center;     margin: 0 auto 12px;}
    .pdp_sec17{ margin-top: 60px;}
    .pdp_sec17 h2{font-size: 33px; letter-spacing: 0.25px;  margin-bottom:15px; text-transform: capitalize; line-height: 1;
        font-weight: 400;}
    .pdp_sec8{      max-width: 1050px; margin: 0 auto; display: block;  margin-top: 60px;}
    .pdp_sec8 > div:nth-child(2) { max-width: 900px; margin: 0;margin-left: 12px; border-radius: 5px;
        margin-bottom: 16px;    overflow: hidden;  padding-left: 25px;padding-right: 0; }
        .pdp_sec8 > div:nth-child(3) { max-width: 900px; margin-right: 12px; border-radius: 5px;
            margin-bottom: 16px;padding-left: 0; overflow: hidden;    padding-right: 25px;}
            .pdp_sec8 > div:nth-child(4) { max-width: 900px; margin: 0;margin-left: 12px; border-radius: 5px;
                margin-bottom: 16px; overflow: hidden;  padding-left: 25px;padding-right: 0; }
                .pdp_sec8 > div:nth-child(5) { max-width: 900px; margin-right: 12px; border-radius: 5px;
                    margin-bottom: 16px;padding-left: 0; overflow: hidden;    padding-right: 25px;}
.pdp_sec8 > div > div {align-items: center;}                   
.pdp_sec2{ position: relative; left: 0; bottom: 0;  left: 0; width: 100%;    box-shadow: initial; border-radius: 0; width: 100%; overflow: inherit;    margin-top: 28px;}
    .pdp_sec8 h3 { width: 100%; padding-top: 0;  font-size: 23px;letter-spacing: 0.15px; 
          font-weight: 600; padding-bottom: 10px;    line-height: 1.3;}
          .pdp_sec8 p { font-size: 13px;    line-height: 1.8;  margin-bottom: 0; width: 100%;}
          .pdp_sec8 span{ display: flex;    flex-direction: column; justify-content: flex-end; align-items: flex-end; margin: 0;}
          .pdp_sec8 > div:nth-child(3) span,    .pdp_sec8 > div:nth-child(5) span{align-items: flex-start;}
          .pdp_sec8 > div:last-child{ margin-bottom: 0;}
          .pdp_sec9{ margin-top: 60px;}
          .pdp_sec9 .h2pdp_sec9{font-size: 33px; letter-spacing: 0.25px;  margin-bottom: 15px; text-transform: capitalize; line-height: 1;
            font-weight: 400;}
            .desk_pdp_sec9 { position: relative;width:930px; display: flex; border: 1px solid #3e4245;border: 1px solid #3e4245; padding:25px 20px; min-height: 350px; align-items: center; margin: 0 auto;}
                .desk_pdp_sec9 div{ align-items: center;}
                .pdp_sec9 ul li span img{ height: 200px;}
                .pdp_sec9_colm{padding-top: 0;}
                .pdp_sec9_colm p b { font-size: 16px;    color: #000000;    letter-spacing: 0.15px;    padding-bottom: 6px;
                    display: block;}
                    .pdp_sec9_colm p{letter-spacing: 0.5px;}
                    .white_btn button {    padding: 12px 0;    background: #3e4245; color: #fff;}	
                     .pdp_sec10{margin-top:60px;}
.pdp_sec10 .h2pdp_sec10{    font-size: 33px; letter-spacing: 0.25px; margin-bottom: 22px;  text-transform: capitalize;
                     font-weight: 400;}
                        .pdp_sec10 li{ width: 16%;}
                        .pdp_sec11{margin-top:60px;}
                        .pdp_sec11 .h2pdp_sec11{font-size: 33px; letter-spacing: 0.25px; margin-bottom: 20px;  text-transform: capitalize;
                            line-height: 1; font-weight: 400;}     
 .pdp_sec19{  padding: 50px 0;  width: 100%; display: inline-block;  background: rgb(240 237 237 / 50%);  margin-top: 60px;}                                            
 .pdp_sec19 .h2pdp_sec19{    font-size: 33px; letter-spacing: 0.25px; margin-bottom:15px;  text-transform: capitalize;
    line-height: 1; font-weight: 400;}
    .pdp_sec19 iframe{ width: 56%; float: left; border-radius: 5px;    margin-right: 24px;    height: 290px;}
    .pdp_sec19 p{font-size: 13px; letter-spacing: 0.5px; line-height: 1.9;}   
    .pdp_sec12{ margin-top: 0px; padding-top: 60px;}
    .pdp_sec12 .h2pdp_sec12{    font-size: 33px;letter-spacing: 0.25px; padding-bottom: 20px; text-transform: capitalize;  line-height: 1;
 font-weight: 400;}
 .pdp_sec12 .star_rating { float: none;}
 .pdp_sec12 li h3{line-height: 1.2;font-size: 23px; font-weight: 500; padding-bottom: 12px;
    width: 80%; letter-spacing: 0.15px;}
    .pdp_sec12 p {width: 80%; font-size: 13px;margin-bottom: 15px;letter-spacing: 0.5px;}
    .pdp_sec12 li h3 span { font-size: 13px;}
    .pdp_sec12 li{ margin-bottom: 20px; padding-bottom: 20px; }
    .pdp_sec12 p:last-child{ margin-bottom: 0; color: #3e4245;}
    .pdp_sec13{ margin-top: 60px;}
    .pdp_sec13 .h2pdp_sec13{font-size: 33px; letter-spacing: 0.25px;     padding-bottom: 20px; text-transform: capitalize;
 line-height: 1; font-weight: 400;}
 .ques{ font-size: 16px;    letter-spacing: 0.15px;}
 .pdp_sec14{ margin-top:60px;}
 .pdp_sec14 .h2pdp_sec14{    font-size: 33px; letter-spacing: 0.25px; margin-bottom:10px; text-transform: capitalize;
 line-height: 1; font-weight: 400;}
 .kama_journal_box h4{font-size: 23px; line-height: 1.2;  font-weight: 500;  padding-bottom: 10px;color: #000000;}
 .kama_journal_box{ padding: 20px;}
 .kama_journal_box span{ border-radius: 0; margin-bottom: 15px;}
 .product .gallery{ position: relative; width: 455px;    padding:40px 20px;  background: #f6f6f6;margin-left: 80px; padding-top: 0; padding-left: 0; padding-right: 0;}
 .product{ padding-top: 20px;max-width: 1260px; margin: 0 auto;}
 .product .gallery:before{ display: none;}
 .product .gallery div button{position: relative; top:30px; }

 .productLayoutNew .gallery{ position: relative; width: 100%; max-width: 95%; padding:40px 20px;  background: #fff; padding-top: 0; padding-left: 0; padding-right: 0;}
 .productLayoutNew{ padding-top: 20px;max-width: 1260px; margin: 0 auto;}
 .productLayoutNew .gallery:before{ display: none;}
 .productLayoutNew .gallery div button{position: relative; top:30px; }

 .pdp_sec1 { margin-top: 0; padding: 0;    box-shadow: inherit; border-radius: 0;}
 .pdp_sec1 .pwa_desc {
    margin-bottom: 20px;
    font-size: 13px;
    letter-spacing: .5px;
    line-height: 1.9;}
 .pdp_sec1 h1{    font-size: 40px; padding-bottom: 18px;  font-weight: 400; line-height: 1.2;letter-spacing: 0.25px;}
 .pdp_sec1 p{    font-size: 13px; letter-spacing: 0.5px;line-height: 1.9;}
 .pdp_sec1 .star_rating{  min-height: 1.5rem;   border-bottom: 1px solid #f0f0f0;padding-bottom:8px;
    margin-bottom: 10px; width: 100%; display: inline-block; }
    .pdp_sec1 .star_rating li{font-family: 'Barlow'; font-size: 12px;letter-spacing: 0.4px; margin-right: 20px;}
    .pdp_sec1 .star_rating img { width: 16px; height: 16px; }
    .pdp_sec1 .star_rating li a{    color: #AD3132;}
    .pdp_sec1 .star_rating li:last-child{ font-weight: 600;    float: right;margin: 0;}
    .icon_shoping ul li.wishlist_box a svg{ opacity: 1; visibility: visible;    width: 32px !important;
        height: 32px !important;  background-size: cover;}
       .productLayoutNew .icon_shoping ul li.wishlist_box a svg{ opacity: 1; visibility: visible;    width: 24px !important;
            height: 24px !important;  background-size: cover;}  
.icon_shoping ul li.share_box a{    width: 32px; height: 32px;    background-size: cover;}
.icon_shoping ul li { margin:10px 0;}
    .icon_shoping ul li a{ cursor: pointer;}
    .icon_shoping{ top: 14px;transform: inherit; right: 20px;}
    .productLayoutNew .icon_shoping{ bottom: 35px; top: auto; transform: inherit; right: 20px;}
    .pdp_price .pdp_sec17{ margin-top: 5px; margin-bottom: 5px;}
    .pdp_sec17 .h4pdp_price span{  margin-right: 10px;letter-spacing: 0.25px;}
    .pdp_price .h4pdp_price{ font-size: 26px; }
    .pdp_sec3 h2{ font-size: 14px;margin-bottom:10px;font-family: 'Open Sans';letter-spacing: 0.25px;}
    .pdp_sec3 ul{ display: block;}
    .pdp_sec3 ul li{ margin: 0;}
    .listing_pdp abbr{    margin-right: 15px; color: #313131;position: relative;top: 1px;letter-spacing: 0.25px;
        font-weight: 400;}
    .listing_pdp .number {width: 99px;padding: 0.7px 10px;}
    .listing_pdp .number input,.listing_pdp .main_number { width: 55px;}
    .listing_pdp .number span{ cursor: pointer;    padding-top: 20px;}
    .pdp_sec3 li a{ cursor: pointer;}
    .pdp_sec3 {margin-top:25px;}
    .pdp_sec2 li button{  border-radius: 0px;min-height: 49.4px;}
    .pdp_sec2 li:first-child button{ border: 1px solid}
    .pdp_sec2 li{ width: 48%;}
    .pdp_sec2 li:last-child{ float: right;}
    .listing_pdp{ position: relative; top:133px;}
    .pdp_sec2 li:last-child button{  color: #fff; background-color: #3e4245;} 
    .desk_pdp_sec2{display: block; width: 100%; margin-top:60px;}
    .desk_pdp_sec2 .row{flex-direction: row-reverse; }
    .desk_pdp_sec2 .col-md-4 .h2desk_pdp_sec2{    font-size: 23px; letter-spacing: 0.15px; font-weight: 600;  padding-bottom: 15px;font-family: 'Bell MT'; color: #3e4245;   line-height: 1.3;}
    .pdp_sec4{ margin-top: 0;padding: 25px;    margin-bottom: 30px;    padding-bottom: 15px;}
    .pdp_sec6{margin-top:44px;    padding: 25px 30px 20px;}
    .pdp_sec4 h6{    color: #313131;padding-bottom: 2px;}
    .pdp_sec5{margin-top:0px;padding: 25px;    padding-left: 58px;}
    .pdp_sec6 .h2pdp_sec6{font-size: 33px; font-weight: 400; margin-bottom: 20px; letter-spacing: 0.25px;text-transform: capitalize;}
    .pdp_sec6 p{    font-size: 13px;letter-spacing: 0.5px; position: relative;  padding-left: 30px;line-height: 1.9;
 margin-bottom: 12px;}
 .pdp_sec16{ padding-top: 55px;}
 .pdp_sec16 h2{font-size: 33px;letter-spacing: 0.25px;margin-bottom: 15px;color: #3e4245;  text-transform: capitalize; font-weight: 400; line-height: 1;}
 .pdp_sec16 ul li, .pdp_sec17 ul li{font-size: 13px; letter-spacing: 0.5px;  padding-bottom: 4px;}
 .pdp_sec16 ul li h6, .pdp_sec17 ul li h6{    font-size: 13px; padding-bottom: 4px;font-weight: 600;}
 .desk_pdp_sec2 p{ color: #3e4245;   font-size: 13px;letter-spacing: 0.5px; line-height: 1.9;}
 .desk_pdp_sec2 p a{color: #3e4245;}
 .pdp_sec5 a { float: none;}
 .pdp_sec16 blockquote{    padding: 20px 15px;}
 .pdp_sec16 blockquote span img{ width:25px;}
 .pdp_sec16 blockquote span:first-child { top: -12px;}
 .pdp_sec16 blockquote span:last-child{bottom: -12px;}
 .pdp_sec16 abbr{    padding: 20px 15px;}
 .pdp_sec16 abbr span img{ width:25px;}
 .pdp_sec16 abbr span:first-child { top: -12px;}
 .pdp_sec16 abbr span:last-child{bottom: -12px;}
 .pdp_sec88 h2{font-size: 33px; font-weight: 400;letter-spacing: 0.25px;margin-bottom: 15px;text-transform: capitalize;line-height: 1;}
 .modal { background: #faf7f5;    max-width: 900px; border-radius: 5px;padding: 30px 30px !important;height: auto;
    overflow: auto; max-height: 500px;}
 .modal h3{    font-size: 23px; font-weight: 600;  padding-bottom: 5px;letter-spacing: 0.25px;}
 .modal p{    font-size: 13px; letter-spacing: 0.5px;line-height: 1.9;}
 .modal a{ cursor: pointer;}
 body .pdp_sec2 .out_of_stock_btn{ width: 48%; float: left; }
 .pdp_sec15{margin-top:60px;}
.pdp_sec15 .h2pdp_sec15{font-size: 33px;margin-bottom: 20px; font-weight: 400; line-height: 1;}	

.recently_viewed_box span a{   float: left; width: 110px;}
.recently_viewed_box span {  height: 124px; }
.recently_viewed_box h3{ font-size: 23px;font-weight: 500;}
.recently_viewed_box p{ font-size: 13px;letter-spacing: 0.5px; margin-bottom: 10px;}
.recently_viewed_box h4{ font-size: 24px; font-size: 600;}
.ans{    padding: 0px 0 20px !important;    border-bottom: 1px solid #f0ddd1 !important;
    margin-top: -2px !important; background: #fff;position: relative;font-size: 13px;
letter-spacing: 0.5px; text-align: justify;}
.pdp_sec14 .col p{letter-spacing: 0.5px;font-size: 13px;line-height: 24px; margin-bottom: 10px;}
.pdp_sec14 .col a{    letter-spacing: 0.5px;text-transform: capitalize; color: #3e4245; font-weight: 600;
    display: block; font-size: 13px;}
    .pdp_sec16 h2:nth-of-type(2){ padding-top: 40px;}
    .pdp_sec16 h2:nth-of-type(3){ padding-top: 40px;}
    .pdp_sec14 ul li{ padding: 0 12px;}
    .pdp_sec15 .row {margin: 0 -8px;  position: relative;}
    .pdp_recently{display: flex; flex-direction: column;}
    .pdp_recently h4{font-family: Open Sans;font-size: 24px; font-weight: 600;}
    .pdp_sec15 ul li div{ height: 100%;}
    .pdp_price .h6pdp_price{font-family: 'Barlow'; font-size: 12px; line-height: 1.2;      font-weight: 500;  display: block; padding-top: 5px; font-style: italic; color: #3e4245;}
}
.listing_pdp_simple{ top:0}
.faqform .inputcolm input{ width: 100%; border: 0;    border-bottom: 1px solid #f0f0f0;padding: 4.4px 0px;}
.faqview_all{ margin-top: 30px; padding-bottom: 30px;}
.faqform .inputcolmtext textarea{ width: 100%;  }
.youtube_icon{position: absolute; pointer-events: none; cursor: pointer; top: 50%;z-index: 9; left: 50%;transform: translate(-50%, -50%);}
.youtube_iframe{height: 100%;}
.youtube_iframe_img{display: flex;flex-direction: column; justify-content: center;height: 99%; position: relative; background: #000;}
.pdp_price .col_expiry{ padding-top: 10px; margin: 0;}
.pdp_price .col_expiry strong{color: #C4601D; }
.youtube_icon_thumbs{ position: relative;}


@media (max-width: 767px){
    .pdpBanner{
        aspect-ratio: 1 / 1;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .desk_pdp_sec2 .col_expiry{ padding-top:20px;}
    .youtube_iframe_img{ background: none;}
    .anniversary_sec12{    padding: 10px 12px 28px; font-size: 14px; }
    .anniversary_sec12 span:before{    font-size: 12px;    bottom: -16px;}
    .anniversary_sec12 span{    padding: 8px 10px;}
    .anniversary_sec12 > div{display:inline-block;}
    .anniversary_sec12 h2{    font-size: 19px;    line-height: 1.3; padding-bottom:6px;    font-weight: 600;}
    .pdp_sec16 ul li h6, .pdp_sec17 ul li h6{    font-size: 14px; padding-bottom: 4px;font-weight: 600;}
    .pdp_sec11 .h2pdp_sec11{ padding-bottom: 10px;}
    .faqform{ padding-top: 20px;}
    .pdp_price .h6pdp_price{ font-size: 12px; line-height: 1.2; font-weight: 500;    display: block; padding-top: 5px; font-style: italic;}
    .pdp_sec7 ul li span{ display:block; padding-bottom: 5px;}
    .pdp_sec15 .carousel_item_padding{padding:0 7px;}
    .pdp_sec15 .row {margin: 0 -5px;}
    .reviewform h3{ font-size: 22px; font-weight: 600;    padding-bottom: 10px;    margin-bottom: 15px;}
    .reviewform h5{ font-size: 19px;padding-bottom: 10px;}
    .inputstar svg{ font-size:30px; }
    .inputstar{ padding-bottom:10px;}
    .reviewform .white_btn button{    background: #c3601d; color: #fff; width: 100%;}
    .review_view_main .view_all{ padding-bottom: 20px;}
    .reviews_rating{ padding-top: 0px; padding-bottom: 20px;}
    .inputcolmtext textarea{ min-height: 100px;}
    .product .gallery{padding: 50px 0 0px 0;}
    .productLayoutNew .gallery{padding: 50px 0 0px 0; background: #fff;}
    .pdp_sec16 h2:nth-of-type(2){ padding-top: 10px;}
    .pdp_sec16 h2:nth-of-type(3){ padding-top: 10px;}
    .pdp_sec11 .carousel_item_padding{padding:0 7px;}
    .pdp_product_scroll{  margin: 0 -7px;}
    .pdp_sec11 h2{ padding-bottom: 10px;}
    .pdp_sec13{margin-top:15px; }
    .pdp_sec8 span {display: flex;margin-top: 0;flex-direction: column;
 height: 100%;justify-content: center;    align-items: center;}
 .pdp_sec2 { z-index: 99;}
 .pdp_sec19 iframe{display: block; border-radius: 5px; margin-bottom: 10px;}
 .pdp_sec8 > div:nth-child(2) > div,  .pdp_sec8 > div:nth-child(4) > div {flex-wrap: wrap-reverse;}
 .pdp_sec8 > div:nth-child(2) > div,  .pdp_sec8 > div:nth-child(4) > div,  .pdp_sec8 > div:nth-child(3) > div,  .pdp_sec8 > div:nth-child(5) > div{ padding-top: 20px;}
 .pdp_sec8 h3, .pdp_sec8 p{ width: 100%;} 
 .pdp_sec8 span{    border-radius: 5px;  overflow: hidden;}
 .pdp_sec20{ margin-top:20px; }
 .pdp_sec20 h2{ padding-top: 20px;   font-size: 22px; letter-spacing: normal; font-weight: 600;
      margin-bottom: 8px;}
      .pdp_sec20 ul li{font-size: 14px; letter-spacing: 0.25px;}
      .pdp_added{    left: 50%; transform: translate(-50%, 0px); width: 260px;  border-radius: 5px;
 padding: 5px 0;    position: fixed;   bottom: 90px; z-index: 999; background: #edf7ed;}
 .pdp_sec1 .pdp_star_color img{ width: 12px; margin-left: 2px;}
 .simpl_text{ margin-top:10px; display:inline-block; margin-bottom: 0px;line-height: 1.7;} 
 .modal_freetrial_main_colm{ padding: 20px;}
 .modal_freetrial_main{ max-width: 100%;}
 .close_btn{ width: 32px; height: 32px;right: -16px;  top: -16px;}
 
 .pdp_sec11 .pdp_product_scroll .col-9,  .pdp_sec14 .pdp_product_scroll .col-9{ padding: 0 7px;white-space: normal; }
 .pdp_sec14 .pdp_product_scroll{ white-space: nowrap;   padding-top: 10px;padding-bottom: 15px;}
 .listing_pdp abbr { float: none;margin-right: 0; padding-top: 0;padding-bottom: 2px; display: block;}
 .cutoffprice{ font-size: 18px;}
 .cutoffprice small{ font-size: 14px;}
}
.pdp_sec3 ul li span{
    position: absolute;
    color: #AD3132;
    font-size: 12px;
}
.pdp_sec9 button:disabled{
    cursor: not-allowed;
    opacity: .5;
}
.input_check input:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px #faf7f5 inset;
    box-shadow: 0 0 0px 1000px #faf7f5 inset;}

.productLayoutNew{
    max-width: 1720px;
    margin: 0 auto;
}

.product_container{
    width: 100%;
    padding-left: clamp(5rem,5.5vw,6.875rem);
    padding-right: clamp(5rem,5.5vw,6.875rem);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: clamp(3.5rem,4vw,5rem);
}

@media screen and (max-width: 1720px) and (min-width: 992px) {
    .productLayoutNew{
        max-width: 1260px;
        margin: 0 auto;
    }

    .product_container{
        max-width: 1050px;
        padding-right: var(--bs-gutter-x, .75rem);
        padding-left: var(--bs-gutter-x, .75rem);
        margin-right: auto;
        margin-left: auto;
    }
}

@media (max-width: 990px){
    .livelyContainer{
        /* padding: 50px 0px 0px 0px; */
        margin: 0;
    }
    .productLayoutNew{
        width: 100%;
        /* padding-right: var(--bs-gutter-x, 1rem) !important;
        padding-left: var(--bs-gutter-x, 1rem) !important; */
        padding: 0;
        margin: 0 auto;
    }
    .product_container{
        width: 100%;
        /* padding-right: var(--bs-gutter-x, 1rem) !important;
        padding-left: var(--bs-gutter-x, 1rem) !important; */
        padding: 0 !important;
    }
}


